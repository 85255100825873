.defaultWorkoutCard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 160px;
    cursor: pointer;
    border-radius: 8px;
    background-size: cover;
    position: relative;
}

.workoutTextContainer {
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    -webkit-line-clamp: 4; /* number of lines to show */
    line-clamp: 4;
    -webkit-box-orient: vertical;
    padding: 8px;
    z-index: 4;
}

.defaultTitleContainer {
    background: linear-gradient(rgba(0, 0, 0, 0.5), transparent);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    justify-content: space-between;
    align-items: center;
}

.defaultCardTitle {
    font-family: "Gill Sans", sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: var(--white);
    text-shadow: -2px 2px 19px var(--gray-shadow);
}

.defaultCardDesccription {
    font-family: "Gill Sans", sans-serif;
    font-size: 12px;
    color: var(--white);
    text-shadow: -2px 2px 19px var(--gray-shadow);
}

.defaultDescriptionContainer {
    text-align: start;
    background: linear-gradient(transparent, rgba(0, 0, 0, 0.75));
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.defaultWorkoutCard:hover .background-video {
    display: block;
}

.defaultWorkoutCard .background-video {
    display: none;
    width: 100%;
    height: 160px;
    position: absolute;
    object-fit: cover;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    border-radius: 8px;
}

.iconWorkoutCard {
    width: 24px;
    height: 24px;
}
