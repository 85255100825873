.breadcrumbsContainer {
    height: var(--breadcrumbs-height);
    padding: 0px 16px;
    width: 100%;
    display: flex;
    align-items: center;
}

.breadcrumbsTextDivider {
    display: flex;
}

.breadcrumbsTextClickable {
    cursor: pointer;
    &:hover {
        color: var(--lilac);
        border-bottom: 0.5px solid var(--lilac);
    }
}
.breadcrumbsText {
    font-family: "Gill Sans", sans-serif;
    color: var(--white);
    font-weight: 500;
    font-size: 18px;
    border-bottom: 0.5px solid var(--black);
}
