.videoLoaderContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: transparent;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -45%);
}

.spinnerContainer {
    height: 180px;
    width: 180px;
}

@media (max-width: 600px) {
    .videoLoaderContainer {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .spinnerContainer {
        height: 120px;
        width: 120px;
    }
}
