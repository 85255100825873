.logoWrapper {
    display: flex;
    align-items: center;
}

.logoImg {
    width: 40px;
    height: 40px;
    img {
        width: 100%;
        height: 100%;
    }
}

.logoText {
    font-size: 16px;
    margin: 0;
    margin-left: 4px;
    font-family: "Gill Sans", sans-serif;
    color: #fff;
    font-weight: 700;
}
