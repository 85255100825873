.workoutsContainer {
  grid-row: 1;
  width: 100%;
  align-content: start;
  margin-bottom: 32px;
  display: grid;
  grid-template-areas: "left right right";
  grid-template-columns: 250px 1fr 32px;
  grid-template-rows: 1fr;
  height: 100%;
}

.workoutsScroll {
  margin-top: 32px;
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;
}
.workoutsListCards {
  min-width: calc(100%);
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  gap: 1rem;
  overflow-y: scroll;
}

// TO DO: add filters for mobile version
@media (max-width: 650px) {
  .workoutsContainer {
    grid-template-areas: "center";
    grid-template-columns: 1fr;
  }
  .workoutsContainer .workoutsFilter {
    display: none;
  }
}
