.defaultCoachCard {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 200px;
    height: 250px;
    cursor: pointer;
    border-radius: 8px;
    background-size: cover;
    cursor: pointer;
}

.defaultCoachInfo {
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    width: 100%;
    bottom: 0;
}

.defaultCoachDescription {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4; /* number of lines to show */
    line-clamp: 4;
    -webkit-box-orient: vertical;
    font-size: 12px;
    text-align: start;
    color: var(--white);
    padding: 8px;
    text-shadow: -2px 2px 19px var(--gray-shadow);
    background: linear-gradient(transparent, rgba(0, 0, 0, 0.75));
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    font-family: "Gill Sans", sans-serif;
    font-weight: 500;
    font-size: 16px;
}

@media (max-width: 910px) {
    .defaultCoachCard {
        width: 250px;
        height: 350px;
    }
}

@media (max-width: 830px) {
    .defaultCoachCard {
        width: calc(100vw - 48px);
    }
}
