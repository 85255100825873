.fullScreenControlsIcon {
  width: 28px;
  height: 28px;
  cursor: pointer;
  margin-left: 6px;
}

@media (max-width: 600px) {
  .fullScreenControlsIcon {
    width: 24px;
    height: 24px;
  }
}
