.buttonContainer {
    display: flex;
}

.button {
    border: 0;
    outline: 0;
    padding: 8px 1em;
    border-radius: 15px;
    background-color: var(--lilac);
    &:hover {
        background-color: var(--lilac);
    }
    font-family: "Gill Sans", sans-serif;
    color: var(--white);
    font-weight: 500;
    font-size: 16px;
    white-space: nowrap;
    cursor: pointer;
}

.darkButton {
    background-color: var(--light-gray);
    &:hover {
        background-color: var(--lilac);
    }
}

.darkActiveButton {
    background-color: var(--black);
    &:hover {
        background-color: var(--black);
    }
}
