body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body, #root {
  height: 100%;
}

svg + svg {
  filter: drop-shadow(0 0 8px red);
  overflow: visible;
}


:root {
  --light-pink: rgba(238,174,202,0.6);
  --light-blue: rgba(148,187,233,0.5);
  --lilac: rgba(199,147,235,1);
  --light-lilac: rgba(199,147,235,0.4);
  --cacao: rgb(48, 27, 40);
  --black: rgb(0, 0, 0);
  --chocolate: rgb(82, 54, 52);
  --white: rgb(255,255, 255);
  --silver: #aaa;
  --light-gray: #777;
  --gray: #333;
  --graphitic: #1f1f1f;
  --mine-shaft: #222;
  --charcoal: #141414;
  --tundora: #444;
  --gray-shadow: #9FA0AA;
  --error: #D55C65;
  --icons-shadow: rgba(0, 0, 0, 0.2);

  --navbar-height: 60px;
  --breadcrumbs-height: 42px;
  --navbar-breadcrumbs-heigth: 102px;

}

a:link {
  text-decoration: none;
  color: var(--lilac);
}

a:visited {
  text-decoration: none;
  color: var(--light-pink);
}

a:hover {
  text-decoration: underline;
  color: var(--light-pink);
}

a:active {
  text-decoration: underline;
  color: var(--light-pink);
}

.unselectableItems {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;           /* Non-prefixed version, currently
                                  not supported by any browser */
}


/* Scroll bar stylings */
::-webkit-scrollbar {
  width: 14px;
  height: 14px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 14px; 
  border: 4px solid var(--black);
  background-clip: padding-box;
  background-color: var(--lilac);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: var(--lilac);
}