.workoutsFilter {
    display: flex;
    flex-direction: column;
    flex: 1;
    border-right: var(--lilac) solid 1px;
    margin: 32px 0px 32px 24px;
    overflow-y: scroll;
}

.workoutsFilterTitle {
    font-family: "Gill Sans", sans-serif;
    color: var(--white);
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 12px;
    padding-right: 24px;
}

.inputContainerFilters {
    margin: 16px 0px;
    padding-right: 24px;
}

.filterBarge {
    background-color: var(--lilac);
    color: var(--white);
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    font-family: "Gill Sans", sans-serif;
    font-size: 12px;
    width: 50px;
    align-self: flex-end;
}
