.playerMenu {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0px 12px 8px;
  height: 60px;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.75));
}

/* .topMenuGradient {
  // height: 55px;
  height: 100%;
  &.menuBlur {
    background: linear-gradient(transparent, rgba(0, 0, 0, 0.75));
  }
} */

@keyframes FadeAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.animate {
  animation: FadeAnimation 3s ease-in 0.1s forwards;
  &:hover {
    animation: none;
  }
}
