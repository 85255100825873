.customPlayer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: var(--black);
}

.playerContainer {
  background-color: var(--black);
  width: 100%;
  height: 100%;
  position: relative;
  margin: 0;
  padding: 0;
  position: fixed;
  overflow: hidden;
}

.smallPlayer {
  // width: 50%;
  // height: calc(100vh - 120px);
  // width: 50%;
  height: 300px;
  position: relative;
}
