.pipControlsIcon {
  width: 36px;
  height: 36px;
  cursor: pointer;
  margin-right: 6px;
  margin-top: -4px;
  margin-left: -4px;
  &.iconPipOff {
    margin-right: 6px;
    margin-top: -8px;
    margin-left: 0px;
  }
}

@media (max-width: 600px) {
  .pipControlsIcon {
    width: 32px;
    height: 32px;
  }
}
